<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-3 text-center">
					<a-button class="btn btn-block btn-warning" style="height: 100px" @click="onAddRecord('service')">
						<i class="fa fa-2x fa-motorcycle"></i>
					</a-button>
					<br />
					Orden de Servicio
				</div>
				<div class="col-md-3 text-center">
					<a-button class="btn btn-block btn-success" style="height: 100px" @click="onAddRecord('sale')">
						<i class="fa fa-2x fa-money"></i>
					</a-button>
					<br />
					Piso de Venta
				</div>
				<div class="col-md-3 text-center">
					<a-button class="btn btn-block btn-info" style="height: 100px" @click="onAddRecord('workshopInput')">
						<i class="fa fa-2x fa-wrench"></i>
					</a-button>
					<br />
					Insumo de Taller
				</div>
				<div class="col-md-3 text-center">
					<a-button class="btn btn-block btn-danger" style="height: 100px" @click="onAddRecord('internal')">
						<i class="fa fa-2x fa-users"></i>
					</a-button>
					<br />
					Consumo Interno
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrDotted" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<b>Rango de Fecha </b>
					<a-icon type="reload" class="pointer" :style="{ fontSize: '14px', color: 'red' }" @click="onResetFilter" />
					<a-range-picker style="width: 100%" @change="getOutboundOrders" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
				</div>
				<div class="col-md-4">
					<b>Orden de Servicio </b>
					<a-input v-mask="'####'" v-model="filters.service_order" @change="onChangeServiceOrder" allowClear />
				</div>
				<div class="col-md-4 text-right pt20">
					<!-- <a-button class="btn btn-info" icon="arrows-alt" @click="onAddRecord">Salida de mercancia</a-button> -->
					<a-button class="btn btn-success ml5" icon="reload" @click="getOutboundOrders" />
				</div>
			</div>
			<div class="row pt20">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="outboundOrders" rowKey="id">
						<div slot="type" slot-scope="record">
							<a-tag :color="record.type == 'out' ? 'green' : 'red'">{{ record.type == 'out' ? 'Salida de almacén' : 'Devolución' }}</a-tag>
							<a-tag v-if="record.service_order" color="purple" style="margin-top: 5px">ODS: {{ record.service_order }}</a-tag>
							<a-tag v-if="record.require_authorization" color="purple" style="margin-top: 5px">Interno</a-tag>
						</div>
						<div slot="order" slot-scope="record" class="text-left">
							<div v-for="(element, index) in record.order" :key="index" class="pt10">
								{{ !element.label.includes(' x ') ? `${element.quantity} x ` : '' }} {{ element.label }}
								<div>
									<small> <b>SKU:</b> {{ element.product.sku }} </small>
								</div>
							</div>
						</div>
						<div slot="receiver" slot-scope="record" class="text-left">
							{{ getReceiverName(record.petitioner) }}
						</div>
						<div slot="action" slot-scope="record">
							<button class="btn btn-sm btn-success" size="small" @click="onAuthorize(record.id)" v-if="record.require_authorization && !record.authorized_by">
								<i class="fa fa-check"></i>
							</button>
							<button class="btn btn-sm btn-warning ml7" size="small" @click="onReturnOrder(record.id)" v-if="record.type == 'out'">
								<i class="fa fa-undo"></i>
							</button>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="returnOrderModal" title="Devolución de productos" :closable="false" width="75%">
			<template slot="footer">
				<a-button key="back" @click="onCancelReturnOrder"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onConfirmReturn"> Continuar </a-button>
			</template>
			<returnOrderComponent @close="onCancelReturnOrder" ref="returnOrderComponent" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import moment from 'moment'
import returnOrderComponent from '@/components/returnOrder'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'outboundOrdersList',
	components: {
		returnOrderComponent,
	},
	directives: {
		mask,
	},
	data() {
		return {
			dateFormat,
			searchText: '',
			returnOrderModal: false,
			columns: [
				{
					title: '# Salida',
					dataIndex: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Tipo',
					scopedSlots: { customRender: 'type' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Fecha',
					dataIndex: 'created_at',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'order' },
					align: 'center',
					width: '40%',
				},
				{
					title: 'Recibe',
					scopedSlots: { customRender: 'receiver' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
		}
	},
	computed: {
		...mapGetters('warehouse', ['spinnerLoaderLabel', 'spinnerLoader', 'outboundOrders', 'filters']),
		...mapGetters('users', ['usersList']),
	},
	beforeMount() {
		if (!this.usersList.length) {
			this.$store.dispatch('users/GET')
		}
	},
	mounted() {
		if (!this.outboundOrders.length) {
			this.getOutboundOrders()
		}
	},
	destroyed() {
		this.$store.commit('warehouse/SET_STATE', { outboundOrders: [] })
	},
	methods: {
		getReceiverName(receiver) {
			return utilities.objectValidate(
				this.usersList.find((e) => e.user_id == receiver),
				'fullName',
				'',
			)
		},
		async onAddRecord(type) {
			this.$store.commit('warehouse/SET_STATE', {
				actualOutboundOrder: {},
				outboundType: type,
			})

			if (type == 'service') {
				await this.$store.dispatch('serviceOrders/GET_OPEN_SERVICE_ORDERS')
			}

			this.$router.push('/warehouse/operations/create')
		},
		onEditRecord(id) {
			this.$store.dispatch('products/GET_OUTBOUND_ORDER', { id, fromWarehouse: true })
		},
		getOutboundOrders() {
			let payload = {
				startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
				endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
			}

			if (this.filters.service_order != '') {
				payload.service_order = Number(this.filters.service_order)
			}

			this.$store.dispatch('warehouse/GET_OUTBOUND_ORDERS', payload)
		},
		onResetFilter() {
			this.$store.commit('warehouse/SET_STATE', {
				filters: {
					defaultDate: [moment().startOf('day').format(dateFormat), moment().endOf('day').format(dateFormat)],
				},
			})
			this.getOutboundOrders()
		},
		onCancelReturnOrder() {
			this.returnOrderModal = false
			this.$store.commit('warehouse/SET_STATE', {
				actualOutboundOrder: {},
			})
			this.$refs.returnOrderComponent.cleanLocal()
		},
		onAuthorize(id) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas autorizar la entrega de productos?`,
				icon: 'warning',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = {
						id,
						...this.outboundOrders.find((e) => e.id == id),
					}
					this.$store.dispatch('warehouse/AUTHORIZE_OUTBOUND_ORDERS', payload)
				}
			})
		},
		onReturnOrder(id) {
			// console.log('onReturnOrder', id)
			this.$store.dispatch('warehouse/GET_ONE', id)
			this.returnOrderModal = true
		},
		onConfirmReturn() {
			// this.$refs.returnOrderComponent.onValidateQuantity()
			this.$refs.returnOrderComponent.onValidateQuantity()
		},
		onChangeServiceOrder(e) {
			let { value } = e.target

			if (value.length == 4) {
				this.getOutboundOrders()
			}

			if (!value) {
				this.$store.commit('warehouse/SET_STATE', {
					filters: {
						...this.filters,
						service_order: '',
					},
				})
				this.getOutboundOrders()
			}
		},
	},
}
</script>

<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<a-tabs @change="onChangeTab" :activeKey="activeTab" :defaultActiveKey="activeTab">
						<a-tab-pane v-for="element in actualSubmenus" :tab="element.title" :key="element.key" force-render>
							<div v-if="element.key.includes('_products')">
								<products />
							</div>
							<div v-if="element.key.includes('_suppliers')">
								<suppliers />
							</div>
							<div v-if="element.key.includes('_quoter')">
								<tiresQuoter />
							</div>
							<div v-if="element.key.includes('_operations')">
								<warehouseOutbounds />
							</div>
						</a-tab-pane>
					</a-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import products from '@/views/products'
import suppliers from '@/views/suppliers'
import tiresQuoter from '@/views/tires-quoter'
import warehouseOutbounds from '@/views/products/outbound'
import utilities from '@/services/utilities'

export default {
	name: 'AlmacenView',
	components: {
		products,
		suppliers,
		tiresQuoter,
		warehouseOutbounds,
	},
	computed: {
		...mapState(['settings']),
		actualSubmenus() {
			if (utilities.objectValidate(this.settings, 'menu', false)) {
				let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
				this.onChangeTab(this.settings.menu[index].submenus[0].key)
				if (utilities.objectValidate(this.settings, ['menu', index, 'submenus'], false)) {
					return this.settings.menu[index].submenus
				}
				return []
			} else {
				return []
			}
		},
	},
	data() {
		return {
			activeTab: '',
		}
	},
	methods: {
		onChangeTab(key) {
			this.activeTab = key
		},
	},
}
</script>